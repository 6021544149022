import axios from "axios";
import router from "../../router/router"

export const http = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_HOST
});

export const httpSecurity = axios.create({
    baseURL: process.env.VUE_APP_SECURITY_HOST
});

http.defaults.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
http.defaults.headers.common['company-id'] = localStorage.getItem('company');
http.defaults.headers.common['security-token'] = localStorage.getItem('security_token');
http.defaults.headers.common['refresh-security-token'] = localStorage.getItem('refresh_security_token');
http.defaults.headers.common['estancia-id'] = localStorage.getItem('estancia_id');

http.interceptors.response.use((resp) => {
    return resp
}, (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
        localStorage.clear();
        return router.push({name: 'LoginComponent'})
    }
    
    return Promise.reject(error);
});
