<script>
export default {
    name: 'ErrorComponent',

    methods: {
        errorMsg(error, type = 'error') {
            const erro = error.response.data?.message;

            if (erro) {
                this.toastPop(erro, type);
            } else {
                const erro = error.response.data?.validations.errors;

                if (erro.sql || !erro || erro.length === 0) {
                    this.toastPop(error.response.data.validations.message, type);
                } else if (erro.msg) {
                    (Array.isArray(erro.msg)) ? erro.msg.forEach(msg => this.toastPop(msg, type)) : this.toastPop(erro.msg, type);
                } else {
                    for (let column in erro) {
                        for (let msg in erro[column]) {
                            this.toastPop(erro[column][msg], type);
                        }
                    }
                }
            }
        },

        toastPop(msg, type = 'error') {
            switch (type) {
                case 'info':
                    this.toast.info(msg, {timeout: 3000});
                    break;
                default:
                    this.toast.error(msg, {timeout: false});
            }
        },
    },
};
</script>
